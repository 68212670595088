import { produce } from "immer";
import { createContext, useContext } from "react";
import { DEFAULT_DOMAIN_DATA, DomainData } from "shared/types/domain-data";
import { createStore, useStore as useZustandStore } from "zustand";
import { devtools } from "zustand/middleware";
import { InteractionCompleteEvent, NewMessageEvent } from "../interfaces";
import { PreloadedStoreInterface } from "./StoreProvider";

export interface StoreInterface {
  domainData: DomainData;
  currentSession: CurrentSession;
  updateDomainData: (domainData: DomainData) => void;
  updateCurrentSession: (currentSession: CurrentSession) => void;
  setInitialInteractionEvent: (initialInteractionCompleteEvent: InteractionCompleteEvent) => void;
  addInitialMessageEvent: (initialMessageEvent: NewMessageEvent) => void;
  reset: () => void;
}

export type CurrentSession = {
  initialNewMessageEvents: Array<NewMessageEvent>;
  initialInteractionCompleteEvent: InteractionCompleteEvent | null;
  conversation_id: string;
  isBeingProcessed: boolean;
  is_finished: boolean;
  isNewConversation?: boolean;
} | null;

function getDefaultInitialState() {
  let rehydratedSession = null;

  if (typeof window !== "undefined") {
    // Attempt to rehydrate the currentSession from localStorage
    const stringifiedSession = localStorage.getItem("currentSession");
    rehydratedSession = stringifiedSession ? JSON.parse(stringifiedSession) : null;
  }
  return {
    domainData: DEFAULT_DOMAIN_DATA,
    currentSession: rehydratedSession,
  };
}

export type StoreType = ReturnType<typeof initializeStore>;

const defaultStore = initializeStore(getDefaultInitialState());

const storeContext = createContext<StoreType>(defaultStore);

export const Provider = storeContext.Provider;

export function useStore<T>(selector: (state: StoreInterface) => T) {
  const store = useContext(storeContext);
  return useZustandStore(store, selector);
}
export function initializeStore(preloadedState: PreloadedStoreInterface) {
  return createStore<StoreInterface>()(
    devtools((set) => ({
      ...getDefaultInitialState(),
      ...preloadedState,
      updateDomainData: (domainData: any) => {
        set({ domainData });

        // Persist updated domainData to localStorage
        try {
          const orgData = localStorage.getItem("orgData");
          const parsedOrgData = JSON.parse(orgData || "{}");
          const updatedOrgData = {
            ...parsedOrgData,
            domainData,
          };
          localStorage.setItem("orgData", JSON.stringify(updatedOrgData));
        } catch (err) {
          console.log(err);
        }
      },
      updateCurrentSession: (currentSession: CurrentSession) => {
        set({ currentSession });
        // Persist the updated currentSession to localStorage
        localStorage.setItem("currentSession", JSON.stringify(currentSession));
      },
      setInitialInteractionEvent: (interactionCompleteEvent: InteractionCompleteEvent) => {
        set(
          produce((state: StoreInterface) => {
            if (state.currentSession) {
              state.currentSession.initialInteractionCompleteEvent = interactionCompleteEvent;
            }
          }),
        );
      },
      addInitialMessageEvent: (initialMessageEvent: NewMessageEvent) => {
        set(
          produce((state: StoreInterface) => {
            if (state.currentSession) {
              state.currentSession.initialNewMessageEvents.push(initialMessageEvent);
            }
          }),
        );
      },
      reset: () =>
        set({
          domainData: DEFAULT_DOMAIN_DATA,
          currentSession: null,
        }),
    })),
  );
}

export const store = defaultStore;
