type TimeStamp = string;

interface ApiKey {
  created_by: string;
  created_by_org_id: string;
  expires_at: TimeStamp;
  id: string;
}

export interface DomainData {
  apiKeys: ApiKey[];
  enableBilling: boolean;
  favicon: string;
  giTenantId: string;
  logo: string;
  mainDescription: string;
  orgId: string;
  orgName: string;
  subDescription: string;
  title: string;
  [key: string]: unknown;
}

export const DEFAULT_DOMAIN_DATA = {
  apiKeys: [],
  enableBilling: false,
  favicon: "",
  giTenantId: "",
  logo: "",
  mainDescription: "",
  orgId: "",
  orgName: "",
  subDescription: "",
  title: "",
};
