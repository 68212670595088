"use client";
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { createContext, useContext, useEffect, useState } from "react";
import { User } from "../interfaces";
import { getUser } from "../services/userService";
import { useStore } from "../store/store";
import initFirebase from "./initFirebase";

initFirebase();

interface AuthContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  token: string | null;
  setToken: (token: string | null) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes

const checkAndRefreshToken = async (token: string, auth: any) => {
  const decodedToken = await auth.currentUser.getIdTokenResult();
  const expiresIn = new Date(decodedToken.expirationTime).getTime() - Date.now();

  if (expiresIn < REFRESH_THRESHOLD) {
    const newToken = await auth.currentUser.getIdToken(true);
    return newToken;
  }

  return token;
};

export const AuthProvider = ({
  children,
  initialUser,
  initialToken,
}: {
  children: React.ReactNode;
  initialUser: User | null;
  initialToken: string | null;
}) => {
  const domainData = useStore((state) => state.domainData);
  const orgId = domainData?.orgId;
  const [user, setUser] = useState<User | null>(initialUser);
  const [token, setToken] = useState<string | null>(initialToken);
  const updateCurrentSession = useStore((state) => state.updateCurrentSession);
  const router = useRouter();
  const posthog = usePostHog();

  async function logout() {
    const auth = getAuth();
    if (auth) {
      await signOut(auth);
      await fetch("/api/logout");
      // In addition to logging out user -- delete kv session data
      await fetch("/api/delete-session", { method: "POST" });
      router.push("/login?reload=true");
      // Delete local storage conversation session data
      updateCurrentSession(null);
      setUser(null);
      posthog.reset();
    }
  }

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", JSON.stringify(token));
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  useEffect(() => {
    const auth = getAuth();

    const checkToken = async () => {
      if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        const refreshedToken = await checkAndRefreshToken(idToken, auth);
        setToken(refreshedToken);
      }
    };

    const unregisterAuthObserver = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken();
        let userResponse = await getUser({
          orgId,
          role: "Admin",
          token: token,
          userId: firebaseUser.uid,
          showToast: true,
        });
        const preferences = userResponse?.preferences || { enable_response_recommendation: true };
        const current_subscription = userResponse?.current_subscription;
        setUser((prevUser) => ({
          ...prevUser,
          user_id: firebaseUser.uid,
          id: firebaseUser.uid,
          uid: firebaseUser.uid,
          email: firebaseUser.email ?? "",
          name: firebaseUser.displayName ?? "",
          displayName: firebaseUser.displayName,
          org_id: userResponse.org_id,
          phoneNumber: firebaseUser.phoneNumber,
          email_verified: userResponse.email_verified,
          token,
          current_subscription,
          preferences: {
            enable_response_recommendation: preferences.enable_response_recommendation ?? true,
          },
          is_admin: prevUser ? prevUser.is_admin : false,
        }));
        setToken(token);

        // Identify user in PostHog
        posthog.identify(firebaseUser.uid, {
          orgId,
          email: firebaseUser.email,
          name: firebaseUser.displayName,
        });
      } else {
        setUser(null);
        setToken(null);
        console.log("User logged out");
      }
    });

    const unregisterIdTokenObserver = auth.onIdTokenChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken(true);
        let userResponse = await getUser({
          orgId,
          role: "Admin",
          token: token,
          userId: firebaseUser.uid,
          showToast: true,
        });

        const current_subscription = userResponse?.current_subscription;

        setUser((prevUser) => ({
          ...prevUser,
          user_id: firebaseUser.uid,
          id: firebaseUser.uid,
          uid: firebaseUser.uid,
          org_id: userResponse.org_id,
          email: firebaseUser.email ?? "",
          name: firebaseUser.displayName ?? "",
          email_verified: userResponse.email_verified,
          displayName: firebaseUser.displayName,
          phoneNumber: firebaseUser.phoneNumber,
          token,
          current_subscription,
          is_admin: prevUser ? prevUser.is_admin : false,
        }));
        setToken(token);
      } else {
        setUser(null);
        setToken(null);
      }
    });

    const intervalId = setInterval(checkToken, REFRESH_THRESHOLD);

    return () => {
      unregisterAuthObserver();
      unregisterIdTokenObserver();
      clearInterval(intervalId);
    };
  }, []);

  return <AuthContext.Provider value={{ user, setUser, token, setToken, logout }}>{children}</AuthContext.Provider>;
};
